import { annotate } from "rough-notation";

document.addEventListener("DOMContentLoaded", function () {
  const video = document.getElementById("cover-video");
  const links = document.getElementsByClassName("footer__links__link");
  const baseline = document.getElementsByTagName("h2")[0];
  const services = document.getElementsByTagName("ul")[0];
  const main = document.getElementById("main");
  const volUp = document.getElementById("bi-volume-up-fill");
  const volMute = document.getElementById("bi-volume-mute-fill");
  let annotation;

  // Methods
  const onCanPlay = () => {
    video.style.opacity = 1;
  };
  const onMouseEnter = (e) => {
    annotation = annotate(e.target, {
      type: "underline",
      animationDuration: 500,
    });
    annotation.show();
  };
  const onMouseLeave = (e) => {
    annotation.hide();
  };
  const onResize = () => {
    main.style.height = window.innerHeight + "px";
  };
  const onToggleSound = () => {
    if (video.muted === true) {
      video.muted = false;
      volUp.style.display = "block";
      volMute.style.display = "none";
    } else if (video.muted === false) {
      video.muted = true;
      volUp.style.display = "none";
      volMute.style.display = "block";
    }
  };

  main.style.height = window.innerHeight + "px";
  baseline.classList.remove("l");
  services.classList.remove("l");

  //listeners
  window.addEventListener("resize", onResize);
  video.addEventListener("canplay", onCanPlay);
  volUp.addEventListener("click", onToggleSound);
  volMute.addEventListener("click", onToggleSound);
  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener("mouseenter", onMouseEnter);
    links[i].addEventListener("mouseleave", onMouseLeave);
  }
});
